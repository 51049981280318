<template>
    <div>
        <div class="login">
            <div class="logo"><img src="../assets/images/logo.png"></div>
            <div class="login-content">
                <div class="login-content-cen">
                    <div class="login-content-tab">
                        <div class="login-content-tab_list" @click="tabClick(1)" :class="{ login_content_hover: num == 1 }">账号密码登录</div>
                        <div class="login-content-tab_list" @click="tabClick(0)" :class="{ login_content_hover: num == 0 }">微信扫码登录</div>
                    </div>
                    <!--账号密码登录-->
                    <form v-if="num == 1" class="login-content_form">
                        <div class="item">
                            <img src="../assets/images/login_user.png" alt="">
                            <el-input v-model="username" type="text" placeholder="输入账号"></el-input>
                        </div>
                        <div class="item">
                            <img src="../assets/images/login_pwd.png" alt="">
                            <el-input v-model="password" show-password type="password" placeholder="输入密码"></el-input>
                        </div>
                        <!--                        <div class="flex flex-justify-content-between">-->
                        <!--                            <div class="item" style="margin-right: 0.1rem;">-->
                        <!--                                <img src="../assets/images/logo_yzm.png" alt="">-->
                        <!--                                <el-input v-model="seccode" type="text" placeholder="输入验证码"></el-input>-->
                        <!--                            </div>-->
                        <!--                            <div class="item">-->
                        <!--&lt;!&ndash;                                <span @click="createCode" class="item-code" >&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    {{checkCode}}&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </span>&ndash;&gt;-->
                        <!--                                <ValidCode ref="code" @input="createValidCode" />-->

                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="jizhu" style="margin-top: 0.8rem;">
                            <el-checkbox v-model="remember">记住账号密码</el-checkbox>
                        </div>
                        <el-button round class="login-btn" @click="login">立即登录</el-button>
                    </form>
                    <!--微信扫码登录-->
                    <form v-if="num == 0" class="login-content_form flex flex-justify-content-around">
                        <img :src="qrCode">
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
var userAgent = navigator.userAgent.toLowerCase()
if (userAgent.indexOf('electron/') > -1) {
    console.log('桌面应用')
    var Store = window.require('electron-store')
    var storeName = new Store();
} else {
    console.log('浏览器')
}
import ValidCode from "@/components/code";

export default {
    name: "Login",
    components: {
        ValidCode
    },
    data() {
        return {
            num: 1,
            username: '',
            password: '',
            seccode: '',
            checkCode: '',
            remember: false,
            validCode: '',
            qrCode: '',
            loginCode: '',
            timer: ''

        };
    },
    computed: {},
    watch: {},
    created() {
        // // 设置定时器
        // this.timer = setInterval(() => {
        //     this.queryOrder()
        // }, 500);
        // // 离开当前页面时销毁定时器
        // this.$once('hook:beforeDestroy', () => {
        //     clearInterval(timer);
        //     timer = null;
        // })
        // this.wxLogin()
    },
    methods: {
        /***
         * 查询登录状态
         */
        queryOrder() {
            this.$axios.post('api/kuerp/order/index/get_login_status', this.$qs.stringify({
                code: this.loginCode,
            })).then(res => {
                if (res.data.code == 200) {
                    if (res.data.data.status == 2) {
                        this.$store.commit('setKeyWord', '')
                        this.$store.commit('setToken', res.data.data.userinfo.token)
                        this.$store.commit('setUserInfo', { data: res.data.data, status: true, username: res.data.data.userinfo.username, avatar: res.data.data.userinfo.avatar, is_vip: res.data.data.userinfo.is_vip });
                        // console.log('跳转首页')
                        this.$router.push('/Index');
                        clearInterval(this.timer);
                        this.timer = null;

                    }
                    if (res.data.data.status == 3) {
                        this.wxLogin()
                    }
                }
            })
        },
        //接收验证码组件提交的4位验证码
        createValidCode(data) {
            this.validCode = data
        },
        /**
         * 获取微信登录二维码
         * */
        wxLogin() {
            this.$axios.post('api/kuerp/order/index/get_login_qrcode', {}).then(res => {
                if (res.data.code == 200) {
                    console.log('res', res)
                    this.qrCode = res.data.data.url
                    this.loginCode = res.data.data.code
                    clearInterval(this.timer);
                    this.timer = setInterval(() => {
                        this.queryOrder()
                    }, 1000);
                }
            })
        },
        /**
         * tab点击
         * */
        tabClick(e) {
            this.num = e
            if (this.num !== 1) {
                this.wxLogin()
            } else {
                clearInterval(this.timer);
                this.timer = null;
            }
        },
        /**
         * 验证码随机数
         * */
        // createCode() {
        //     let code = "";
        //     const codeLength = 4; //验证码的长度
        //     const random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
        //         'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'); //随机数
        //     for(let i = 0; i < codeLength; i++) { //循环操作
        //         let index = Math.floor(Math.random() * 62); //取得随机数的索引（0~35）
        //         code += random[index]; //根据索引取得随机数加到code上
        //     }
        //     this.checkCode = code; //把code值赋给验证码
        // },
        /**
         * 登录
         * */
        async login() {
            if (!this.username) {
                this.$message.error('请输入用户名');
                return;
            }
            if (!this.password) {
                this.$message.error('请输入密码');
                return;
            }
            // if (!this.seccode) {
            //     this.$message.error('请输入验证码');
            //     this.$refs.code.createdCode()
            //     return;
            // }
            // if(this.seccode.toUpperCase() !==this.validCode.toUpperCase()) {
            //     this.$message.error('验证码错误');
            //     this.$refs.code.createdCode()
            //     return;
            // }
            await this.$axios.post('api/kuerp/order/user/login', {
                username: this.username,
                password: this.password
            }).then(res => {
                if (res.data.code == 200) {
                    this.$store.commit('setKeyWord', '')
                    this.$store.commit('setToken', res.data.data.userinfo.token)
                    this.$store.commit('setUserInfo', { data: res.data.data, status: true, username: res.data.data.userinfo.username, avatar: res.data.data.userinfo.avatar, is_vip: res.data.data.userinfo.is_vip });
                    // console.log('跳转首页')
                    this.$router.push('/Index');
                    var userAgent = navigator.userAgent.toLowerCase()
                    if (userAgent.indexOf('electron/') > -1) {
                        if (this.remember) {
                            //记住密码
                            storeName.set("xlctzkname", this.username);
                            storeName.set("xlctzkpassword", this.password);
                        } else {
                            storeName.delete('xlctzkname');
                            storeName.delete('xlctzkpassword');
                        }
                    } else {
                        if (this.remember) {
                            //记住密码
                            this.$store.commit('setAccount', { username: this.username, password: this.password });
                        } else {
                            this.$store.commit('setAccount', '');
                        }
                    }
                }
            })
        },
    },

    mounted() {
        var userAgent = navigator.userAgent.toLowerCase()
        if (userAgent.indexOf('electron/') > -1) {
            if (storeName.has("xlctzkname")) {
                this.password = storeName.get('xlctzkpassword')
                this.username = storeName.get('xlctzkname')
                this.remember = true
            } else {
                this.remember = false
            }
        } else {
            if (this.$store.state.user.account) {
                let data = this.$store.state.user.account;
                this.password = data.password;
                this.username = data.username;
                this.remember = true;
            }
        }
        // this.createCode();
    }
}
</script>
<style scoped lang="scss">
.login {
    width: 100vw;
    height: 100vh;
    background: url('../assets/images/loginBG.png') no-repeat center;
    background-size: cover;
}

.logo {
    position: absolute;
    top: 1.7rem;
    left: 11.8rem;

    img {
        width: 3.2rem;
        height: 0.72rem;
    }
}

.login-content {
    width: 5.86rem;
    padding: 0.4rem 0 0.4rem 0;
    position: absolute;
    top: 6.3rem;
    left: 14rem;
    transform: translate(-50%, -56%);
    background: #FFFFFF;
}

.login-content-cen {
    width: 3.9rem;
    margin: 0 auto;
}

.login-content-tab {
    display: flex;
    justify-content: space-around;
    font-size: 0.2rem;
    font-weight: 400;
    color: #999999;
    margin-bottom: 0.3rem;
    border-bottom: 0.01rem solid #F6F7F9;
}

.login-content-tab_list {
    width: 1.2rem;
    padding-bottom: 0.1rem;
}

.login_content_hover {
    font-weight: 500;
    color: #333333;
    border-bottom: 0.02rem solid #333333;
}

.login-content_form {
    height: 3.1rem;
}

.login-content p {
    color: #999999;
    font-size: 0.36rem;
    margin-bottom: 0.3rem;
    text-align: center;
    font-weight: bold;
}

.login-content .item {
    display: flex;
    align-items: center;
    border-radius: 0.02rem;
    height: 0.48rem;
    margin-bottom: 0.2rem;
    padding: 0 0.15rem;
    background: #F6F7F9;
}

.login-content .item img {
    width: 0.16rem;
    height: 0.18rem;
}

.login-content .item .item-code {
    width: 1.1rem;
    font-size: 0.24rem;
    font-weight: 1000;
    color: #4B83F0;
    padding: 0 0.1rem;
    text-align-last: center;
}

.login-content .item input {
    flex: 1;
    margin-left: 0.15rem;
    font-size: 0.16rem;
}

.login-content .item input::-webkit-input-placeholder {
    color: #999999;
}

.login-btn {
    display: block;
    background: #4B83F0;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.16rem;
    border-radius: 0.04rem !important;
    width: 3.84rem;
    height: 0.5rem;
    margin: 0 auto;
}

.jizhu {
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    color: #CDCDCD;
    margin-bottom: 0.36rem;
}

.jizhu input {
    width: 0.16rem;
    height: 0.16rem;
    margin: 0 0.09rem 0 0;
}


::v-deep .el-input__inner {
    border: none;
    background-color: transparent;
}

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #CDCDCD;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    color: #4B83F0;
    background-color: #4B83F0;
    border-color: #4B83F0;
}
</style>
